import React, { useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "./Projects.scss";
import { ProjectList } from "./ProjectList";
import * as Icon from "react-bootstrap-icons";
import eu from "../../assets/img/icon2.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const [showAbout, setShowAbout] = useState(undefined);
  const navigate = useNavigate();
  const handleShowAbout = (id) => setShowAbout(id);

  return (
    <section className="projects section-card-revers" id="projects">
      <Container className="container-fluid side-bx container-proj">
        <Row>
          <h2>Projects</h2>
        </Row>
        <Row className="responsive">
          <Col className="col-4 logo-container">
            <img
              src={eu}
              alt="logo"
              className="side-logo space-elem project-logo"
            />
          </Col>

          <Col className="proj-container">
            {ProjectList.map((project) => {
              const handleImageClick = () => {
                if (project.name === "Personal Portfolio") {
                  window.location.href = "#home"; // Navigate to home section
                } else {
                  navigate(`/${project.id}`); // Navigate to the project ID page
                }
              };

              return (
                <div className="card card-project col-md-5" key={project.id}>
                  <div className="card-body">
                    <div className="title-card">
                      <h6>{project.name}</h6>
                    </div>
                    <img
                      className="card-img-top"
                      src={project.imgUrl}
                      onClick={handleImageClick} // Apelează funcția la clic
                      alt="Project"
                      style={{ cursor: "pointer" }} // Adaugă un cursor pointer pentru indicația de clic
                    />

                    <div className="card-body middle">
                      <div className="middle-contain"></div>
                      <div>
                        {project.name === "Personal Portfolio" ? (
                          <Button
                            href="#home"
                            className="btn-card btn-gallery"
                            size="lg"
                          >
                            This Page
                          </Button>
                        ) : (
                          <>
                            {project.button ? (
                              <>
                                <Link
                                  to={{
                                    pathname: `/${project.id}`,
                                  }}
                                >
                                  <Button
                                    className="btn-card btn-gallery"
                                    size="lg"
                                  >
                                    Details
                                  </Button>
                                </Link>
                                <a
                                  href={project.button} // corect
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button
                                    className="btn-card btn-gallery"
                                    size="lg"
                                  >
                                    Visit Site
                                  </Button>
                                </a>
                              </>
                            ) : (
                              <Link
                                to={{
                                  pathname: `/${project.id}`,
                                }}
                              >
                                <Button
                                  className="btn-card btn-gallery"
                                  size="lg"
                                >
                                  Details
                                </Button>
                              </Link>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
      <Modal />
    </section>
  );
};

export default Projects;
