import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Skills.scss";
import "react-multi-carousel/lib/styles.css";
import eu from "../../assets/img/icon9.png";
import { softSkills } from "./SkillsLists";
import { SkillsList } from "./SkillsLists";

const Skills = () => {
  return (
    <section className="skills section-card" id="skills">
      <Container className="side-bx">
        <Row>
          <h2>Skills</h2>
        </Row>
        <Row className="responsive ">
          <Col className="skill-logo col-4">
            <img src={eu} alt="logo" className="side-logo space-elem" />
          </Col>
          <Col>
            <h4 className="skill-type pt-5 ">What do i bring to the table?</h4>
            <Col className="all-skills">
              {/* Soft Skills */}
              <Col className="skills-parts   col-8  ">
                <Col className="space-elem ">
                  <div className="skill-items ">
                    {softSkills.map((skill) => {
                      return (
                        <div
                          className="col-5 m-2 skill-item soft-img border-bottom border-warning"
                          key={skill.id}
                        >
                          <img
                            className="col-6 soft-img"
                            src={skill.src}
                            alt={skill.id}
                          />

                          <span className=" skill-name">{skill.name}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Col>
              {/* Technical Skills */}
              <Col className="skills-parts skill-tech  col-12 ">
                <Col className="space-elem ">
                  <div className="skill-items technical-skills ">
                    {SkillsList.map((skill) => {
                      return (
                        <div
                          className=" m-2 skill-item  card-item border-start border-warning"
                          key={skill.id}
                        >
                          <span className="col-9 skill-name">{skill.name}</span>

                          <img
                            className="col-3"
                            src={skill.src}
                            alt={skill.id}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Skills;
