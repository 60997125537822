import React from "react";
import "./Footer.scss";
import { Container, Row, Col } from "react-bootstrap";
import navIcon1 from "../../assets/img/navIcon1.png";
import navIcon2 from "../../assets/img/navIcon2.png";
import navIcon3 from "../../assets/img/navIcon3.png";
import navIcon4 from "../../assets/img/navIcon4.png";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col className="footer-col text-center">
            <div className="social-icon ">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/adina-maria-perju/"
                id="linkedln"
              >
                <img src={navIcon1} alt="linkedln" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/ungureanu.adinamaria"
                id="facebook"
              >
                <img src={navIcon2} alt="facebook" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/AdinaPerju"
                id="github"
              >
                <img src={navIcon4} alt="github" />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="footer-col footer-navlink">
            <a href="#home" alt="home">
              Home
            </a>
            <a href="#skills" alt="skills">
              Skills
            </a>
            <a href="#projects" alt="projects">
              Projects
            </a>
          </Col>
        </Row>
        <Row className="footer-text">
          <Col className="footer-col footer-text">
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
