import projImg1 from "../../assets/img/projImg1.PNG";
import projImg2 from "../../assets/img/Portfolio.PNG";
import projImg11 from "../../assets/img/projImg11.PNG";
import projImg12 from "../../assets/img/projImg12.PNG";
import projImg13 from "../../assets/img/projImg13.PNG";
import projImg3 from "../../assets/img/projImg3.PNG";
import projImg31 from "../../assets/img/projImg31.PNG";
import projImg32 from "../../assets/img/projImg32.PNG";
import projImg33 from "../../assets/img/projImg33.PNG";
import projImg34 from "../../assets/img/projImg34.PNG";
import { SkillsList } from "../skills/SkillsLists";

export const ProjectList = [
  {
    id: 1,
    name: "Personal Portfolio",
    imgUrl: projImg2,

    description: "This website is created by myself using HTML, CSS and React.",
    about:
      "It is my first live website created by myself and presents my projects I worked on and my knowledge as a web developer.",
    technologies: [
      {
        className: "img-tech",
        src: SkillsList[0].src,
        alt: SkillsList[0].name,
      },
      {
        className: "img-tech",
        src: SkillsList[1].src,
        alt: SkillsList[1].name,
      },
      {
        className: "img-tech",
        src: SkillsList[2].src,
        alt: SkillsList[2].name,
      },
      {
        className: "img-tech",
        src: SkillsList[4].src,
        alt: SkillsList[4].name,
      },
      {
        className: "img-tech",
        src: SkillsList[3].src,
        alt: SkillsList[3].name,
      },
      {
        className: "img-tech",
        src: SkillsList[6].src,
        alt: SkillsList[6].name,
      },
    ],
  },

  {
    id: 2,
    name: "The Team Builder",
    imgUrl: projImg1,
    imgUrls: [
      {
        className: "img-size",
        src: projImg1,
        alt: "Main Slide",
        description: "The main page",
      },

      {
        className: "img-size",
        src: projImg11,
        alt: "First Slide",
        description: "Using a form, the candidate profile is generated",
      },
      {
        className: "img-size",
        src: projImg12,
        alt: "Second Slide",
        description:
          "The candidate list displays the generated profiles as cards",
      },
      {
        className: "img-size",
        src: projImg13,
        alt: "Third Slide",
        description:
          "Selecting a card opens the resume of the candidate in a modal window",
      },
    ],

    description: "Software Development Academy Final Project.",
    about:
      " This project was created in order to allow myself to demonstrate a wide range of skills aquired during the accredited online course of JavaScript that I took. The purpose of the project is an app designed to generate job candidate profiles and to create the resume of the candidate by using a form.",
    technologies: [
      {
        className: "img-tech",
        src: SkillsList[0].src,
        alt: SkillsList[0].name,
      },
      {
        className: "img-tech",
        src: SkillsList[1].src,
        alt: SkillsList[1].name,
      },
      {
        className: "img-tech",
        src: SkillsList[2].src,
        alt: SkillsList[2].name,
      },
      {
        className: "img-tech",
        src: SkillsList[4].src,
        alt: SkillsList[4].name,
      },
      {
        className: "img-tech",
        src: SkillsList[5].src,
        alt: SkillsList[5].name,
      },
      {
        className: "img-tech",
        src: SkillsList[3].src,
        alt: SkillsList[3].name,
      },
      {
        className: "img-tech",
        src: SkillsList[6].src,
        alt: SkillsList[6].name,
      },
    ],
  },
  {
    id: 3,
    name: "Angelica's Essentials",
    button: "https://angelicasessentials.com/",
    imgUrl: projImg3,
    imgUrls: [
      {
        className: "img-size",
        src: projImg3,
        alt: "Main Slide",
        description:
          "The landing page provides a brief description of the store, sections linking to categories, collections, or products, along with language and account creation options.",
      },

      {
        className: "img-size",
        src: projImg31,
        alt: "First Slide",
        description:
          "One of the category pages featuring various collections related to that category and a descriptive text.",
      },
      {
        className: "img-size",
        src: projImg32,
        alt: "Second Slide",
        description:
          "This page lists all the collections available in the store, accompanied by a detailed description.",
      },
      {
        className: "img-size",
        src: projImg33,
        alt: "Third Slide",
        description:
          "One of the collection page featuring multiple products related to that collection, a collection description, and three reasons to choose products from that collection.",
      },
      {
        className: "img-size",
        src: projImg34,
        alt: "Fourth Slide",
        description:
          "The product page contains all available products, a description, three reasons to choose them, and filter options.",
      },
    ],

    description:
      "This online shop is created by myself using Shopify and Liquid.",
    about:
      "I developed a fully functional online store for natural products using the Shopify platform. This was my first project built with Shopify, integrating its standard features and customizing the code through Liquid to enhance the user experience. ",
    technologies: [
      {
        className: "img-tech",
        src: SkillsList[8].src,
        alt: SkillsList[8].name,
      },
      {
        className: "img-tech",
        src: SkillsList[9].src,
        alt: SkillsList[9].name,
      },
    ],
  },
];
