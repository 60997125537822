import React, { useState, useRef } from "react";
import "./Contact.scss";
import { Container, Row, Col } from "react-bootstrap";
import contact from "../../assets/img/icon3.png";
import emailjs from "emailjs-com";

const Contact = () => {
  const formInitDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});

  const handleStateChange = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then((res) => {
        if (res.status === 200) {
          setFormDetails(formInitDetails);
          setButtonText("Send");
          setStatus({
            message:
              "Great, I received your message! I will reach you as soon as possible",
          });
        } else {
          setStatus({
            message: "Something went wrong, please try again later.",
          });
        }
      });
  };

  return (
    <section className="contact section-card" id="connect">
      <Container className="side-bx">
        <Row>
          <h2>Get in Touch</h2>
        </Row>
        <Row className="responsive">
          <Col className=" logo-col col-4">
            <img
              src={contact}
              alt="contact-us"
              className="side-logo space-elem contact-logo"
            />
          </Col>

          <Col className="form-side">
            <form onSubmit={handleSubmit} ref={form}>
              <div className="form__title"></div>
              <Row className="form-row">
                <Row className="py-1">
                  <Col sm={6}>
                    <input
                      required
                      isInvalid
                      name="firstName"
                      className="px-1 form-details"
                      type="text"
                      placeholder="Your First Name"
                      value={formDetails.firstName}
                      onChange={(e) =>
                        handleStateChange("firstName", e.target.value)
                      }
                    />
                  </Col>
                  <Col sm={6} className="px-1">
                    <input
                      required
                      isInvalid
                      className="px-1 form-details"
                      type="text"
                      name="lastName"
                      placeholder="Your Last Name"
                      value={formDetails.lastName}
                      onChange={(e) =>
                        handleStateChange("lastName", e.target.value)
                      }
                    />
                  </Col>
                </Row>

                <Row className="py-1 ">
                  <Col sm={6} className="px-1 ">
                    <input
                      required
                      isInvalid
                      className="px-1 form-details"
                      name="email"
                      type="email"
                      placeholder="Your Email Address"
                      value={formDetails.email}
                      onChange={(e) =>
                        handleStateChange("email", e.target.value)
                      }
                    />
                  </Col>
                  <Col sm={6} className="px-1">
                    <input
                      className="px-1 form-details"
                      type="number"
                      name="phone"
                      placeholder="Your Phone No."
                      value={formDetails.phone}
                      onChange={(e) =>
                        handleStateChange("phone", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row className="py-1">
                  <Col>
                    <textarea
                      required
                      isInvalid
                      className="px-1 form-details"
                      row="6"
                      name="message"
                      placeholder="Type your Message"
                      value={formDetails.message}
                      onChange={(e) =>
                        handleStateChange("message", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <div>
                  <button type="submit" className="py-1 form-btn form-details ">
                    <span className="button-span">{buttonText}</span>
                  </button>
                </div>

                {status.message && (
                  <Col>
                    <p>{status.message}</p>
                  </Col>
                )}
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
