import "../App.scss";
import NavBar from "./navbar/NavBar.jsx";
import ProjectPage from "./projects/ProjectPage";

function ProjectApp() {
  return (
    <div>
      <NavBar />

      <ProjectPage />

      <NavBar />
    </div>
  );
}

export default ProjectApp;
