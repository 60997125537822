import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import "./NavBar.scss";
import navIcon1 from "../../assets/img/navIcon1.png";
import navIcon2 from "../../assets/img/navIcon2.png";
import navIcon3 from "../../assets/img/navIcon3.png";
import navIcon4 from "../../assets/img/navIcon4.png";
import { HashLink } from "react-router-hash-link";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const logo = require("../../assets/img/brand559.png");
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };
  return (
    <div>
      <Navbar
        expand="lg"
        className={scrolled ? "scrolled" : ""}
        // hideNav={hideNav}
        collapseOnSelect
      >
        <Container>
          <Navbar.Brand href="#home" id="logo">
            <img src={logo} alt="logo" />
            {/* <Logo/> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                to="/home"
                data-after="Home"
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                to="/skills"
                data-after="Skills"
                href="#skills"
                className={
                  activeLink === "skills" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("skills")}
              >
                Skills
              </Nav.Link>
              <Nav.Link
                to="/projects"
                data-after="Projects"
                href="#projects"
                className={
                  activeLink === "projects"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("projects")}
              >
                Projects
              </Nav.Link>
              <Nav.Link
                data-after="Contact"
                href="#connect"
                className={
                  activeLink === "contact"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("contact")}
              >
                Contact
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/adina-maria-perju/"
                  id="linkedln"
                >
                  <img src={navIcon1} alt="linkedln" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/ungureanu.adinamaria"
                  id="facebook"
                >
                  <img src={navIcon2} alt="facebook" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/AdinaPerju"
                  id="github"
                >
                  <img src={navIcon4} alt="github" />
                </a>
              </div>
              <HashLink to="#connect">
                <button className="vvd connect-button nav-connect">
                  <span>Let's Connect</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
