import html from "../../assets/img/html.png";
import css from "../../assets/img/css.png";
import bootstrap from "../../assets/img/bootstrap.png";
import node from "../../assets/img/node.png";
import js from "../../assets/img/javascript.png";
import react from "../../assets/img/react.png";
import redux from "../../assets/img/redux.png";
import git from "../../assets/img/git.png";
import perseverence from "../../assets/img/perseverence.png";
import improvment from "../../assets/img/improve.png";
import tolerance from "../../assets/img/tolerance.png";
import openMind from "../../assets/img/openMind.png";
import timeOriented from "../../assets/img/timeOriented.png";
import battery from "../../assets/img/battery.png";
import shopify from "../../assets/img/shopify.png";
import liquid from "../../assets/img/liquid.png";

export const SkillsList = [
  {
    id: "html",
    name: "HTML",
    src: html,
  },
  {
    id: "css",
    name: "CSS",
    src: css,
  },
  {
    id: "javascript",
    name: "JavaScript",
    src: js,
  },
  {
    id: "bootstrap",
    name: "Bootstrap",
    src: bootstrap,
  },
  {
    id: "react",
    name: "ReactJS",
    src: react,
  },
  {
    id: "redux",
    name: "REDUX",
    src: redux,
  },
  {
    id: "node",
    name: "nodeJS",
    src: node,
  },
  {
    id: "git",
    name: "Git",
    src: git,
  },
  {
    id: "shopify",
    name: "Shopify",
    src: shopify,
  },
  {
    id: "Liquid",
    name: "Liquid",
    src: liquid,
  },
];
export const softSkills = [
  {
    id: "perseverence",
    name: "Perseverence",
    src: perseverence,
  },
  {
    id: "improvment",
    name: "Strive for improvement",
    src: improvment,
  },
  {
    id: "tolerance",
    name: "Tolerance",
    src: tolerance,
  },
  {
    id: "openMind",
    name: "An open mind",
    src: openMind,
  },
  {
    id: "timeOriented",
    name: "Time-oriented attitude",
    src: timeOriented,
  },
  {
    id: "battery",
    name: "A full battery",
    src: battery,
  },
];
