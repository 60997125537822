import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import eu from "../../assets/img/icon5.png";
import "./Banner.scss";

const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Amazing", "Great", " Together"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <section className="banner" id="home">
      <Container className="banner-size">
        <Row className="align-items-center ">
          <Col
            className="banner-text"
            // xs={12} md={12} xl={7}
          >
            <div className="welcome-div">
              <div className="tagline">Welcome to my Portfolio </div>
            </div>
            <h1>
              {"Let's Build  Something "}
              <div className="wrap"> {text}</div>
            </h1>

            <p>
              {/* I am a junior FrontEnd Developer from Romania and currently living
              in Switzerland. */}
              {/* I am Adina Perju, Freelance Web Developer that works for you. */}
              I am Adina Perju, a Freelance Frontend Web Developer based in
              Pieterlen, Switzerland.
              {/* <br /> */}
              {/* I work with small business owners, startups, and individuals who
              want to get online visibility. */}
              <br />
              With a background as a Quality Engineer in the automotive
              industry, I bring a meticulous and detail-oriented approach to web
              development. My passion lies in crafting intuitive and engaging
              web experiences that delight users.
              <br />
              This website is a testament to my skills and dedication, built
              using React JS and Bootstrap. I strive to create seamless and
              interactive web solutions that not only meet but exceed user
              expectations.
            </p>
            <div className="div-btn">
              <button className="orange-btn left-btn">
                <a className="link-decor" href="#connect" alt="connect-button">
                  Let's connect
                  <Icon.ArrowRightCircle color="#F9B872" size={25} />{" "}
                </a>
              </button>
            </div>
          </Col>
          <Col
            className="banner-col"
            //  xs={12} md={12} xl={5}
          >
            <img
              src={eu}
              alt="Headder Img"
              className="banner-img"
              // width={500}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
