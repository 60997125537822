import "./App.scss";
import AppSections from "./components/AppSections";
import ProjectPage from "./components/projects/ProjectPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div>
      {/* <InProgress/> */}
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<AppSections />} />
          <Route path="/:id" element={<ProjectPage />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
