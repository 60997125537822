import React, { useState } from "react";
import "./Projects.scss";
import { Container, Row, Col, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Carousel from "react-bootstrap/Carousel";
import { ProjectList } from "./ProjectList";
import { Link, useParams } from "react-router-dom";
const ProjectPage = () => {
  const [showGalery, setShowGalery] = useState(undefined);
  const [showAbout, setShowAbout] = useState(undefined);
  const handleCloseGalery = () => setShowGalery(undefined);
  const handleCloseAbout = () => setShowAbout(undefined);
  const handleShowGalery = (id) => setShowGalery(id);
  const handleShowAbout = (id) => setShowAbout(id);

  let { id } = useParams();
  return (
    <div className="projects-elem">
      {ProjectList.filter((project) => project.id == id).map((project) => {
        return (
          <Container>
            <div key={project.id} className="proj-description ">
              {project.button ? (
                <>
                  <div className="back-btn-top position-absolute top-0 start-0 m-xl-5 m-md-0">
                    <Link className="link-decor" to="/">
                      <Button className="orange-btn">
                        <Icon.ArrowLeftCircle color="#F9B872" size={25} />
                        <p>Back</p>
                      </Button>
                    </Link>
                  </div>
                  <div className="visit-btn-top position-absolute top-0 end-0 m-xl-5 m-md-0">
                    <a
                      className="link-decor"
                      href={project.button}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="orange-btn">
                        <p>Visit Site</p>
                        <Icon.ArrowRightCircle color="#F9B872" size={25} />
                      </Button>
                    </a>
                  </div>
                </>
              ) : (
                <div className="back-btn-top position-absolute top-0 start-0 m-xl-5 m-md-0">
                  <Link className="link-decor" to="/">
                    <Button className="orange-btn">
                      <Icon.ArrowLeftCircle color="#F9B872" size={25} />
                      <p>Back</p>
                    </Button>
                  </Link>
                </div>
              )}
              <h1 className="proj-title">{project.name}</h1>

              <Carousel fade data-bs-theme="dark" interval={2000}>
                {project.imgUrls.map((item, index) => {
                  return (
                    <Carousel.Item>
                      <img src={item.src} alt={item.alt} />
                      <Carousel.Caption>
                        <p className=" rounded  " key={item.alt}>
                          {item.description}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
              <Container className="about-container">
                <Row className="descr-row">
                  <Col className="col-md-12 col-lg-4 descr-proj">
                    {project.description}{" "}
                  </Col>{" "}
                  <Col className="col-1">
                    <Icon.ArrowRightCircle color="#F9B872" size={25} />
                  </Col>{" "}
                  <Col className=" about-proj">{project.about}</Col>{" "}
                </Row>
                <Row className=" descr-row ">
                  <Col className="col-md-12 col-lg-4  descr-proj">
                    Used technologies{" "}
                  </Col>{" "}
                  <Col className="col-1">
                    <Icon.ArrowRightCircle color="#F9B872" size={25} />
                  </Col>
                  <Col className=" tech-img">
                    <ul>
                      {project.technologies.map((tech, index) => {
                        return (
                          <li
                            key={index}
                            className="col-xl-1 col-lg-3 col-md-6 "
                          >
                            <img
                              src={tech.src}
                              alt={tech.alt}
                              className={tech.className}
                            />
                            <div className="tech-size">{tech.alt}</div>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>
        );
      })}

      <Link className=" link-decor" to="/">
        <Button className="orange-btn right-btn">
          <Icon.ArrowLeftCircle color="#F9B872" size={25} />{" "}
          <span>Back to the main page</span>{" "}
        </Button>
      </Link>
    </div>
  );
};

export default ProjectPage;
