import Banner from "./banner/Banner";
import Skills from "./skills/Skills.jsx";
import Projects from "./projects/Projects";
import Contact from "./contact/Contact";
import { Link } from "react-router-dom";
import NavBar from "./navbar/NavBar";
import Footer from "./footer/Footer";

function AppSections() {
  return (
    <div>
      <NavBar />
      <Banner />

      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default AppSections;
